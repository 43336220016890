import { useEffect } from 'react';
import translate from '../../components/languages';
import useScript from '../../utils/hooks/useScript';

declare global {
  interface Window {
    orbiwidget: any; // Use 'any' if you don't have specific type definitions
  }
}

type TPropsAvailability = {
  propertyName: string;
  availabilityCalendarUrl: string;
};

const scriptCalendar = `/assets/js/calendarHostfully.js`;
function Availability({
  propertyName,
  availabilityCalendarUrl,
}: TPropsAvailability) {
  const statusCalendar = useScript(scriptCalendar);

  useEffect(() => {
    const propertyIdFriendly = availabilityCalendarUrl
      .split('.ics')[0]
      .split('calendar/')[1];

    if (statusCalendar === 'ready' && propertyIdFriendly) {
      new window.orbiwidget('widget34725', {
        id: propertyIdFriendly,
        showTentative: 1,
        showAvailability: true,
        monthsToDisplay: 3,
        name: propertyName,
      });
    }
  }, [statusCalendar, availabilityCalendarUrl]);
  return (
    <div
      className="show-only-desktop"
      style={{ position: 'relative', zIndex: 1 }}
    >
      <div
        className="title-description"
        style={{ position: 'absolute', zIndex: '2' }}
      >
        <p>{translate.t('availability').toString()}</p>
      </div>
      <div id="widget34725"></div>
    </div>
  );
}
export default Availability;
