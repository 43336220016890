import { BrowserView, MobileView } from 'react-device-detect';
import translate from '../../components/languages';
import { IProperty } from '../../utils/interfaces/hostfully.property.interface';

//assets
import bath from '../../../src/assets/11. FICHA/bath.svg';
import dorm from '../../../src/assets/11. FICHA/dorm.svg';
import guests from '../../../src/assets/11. FICHA/guests.svg';
import home from '../../../src/assets/11. FICHA/home.svg';

function PropertySummary(property: IProperty) {
  console.log('property new', property);

  return (
    <div className="checkout-prop-summary checkout-mobile-container">
      <span className="type-check">
        <img src={home} width="5%" alt="Loading..." className="mr-2" />
        <BrowserView>{property?.propertyType}</BrowserView>
        <MobileView>
          {property?.propertyType === 'APARTMENT' && 'APTO.'}
        </MobileView>
      </span>
      <span className="type-check ml-2">
        <img src={guests} width="5%" alt="Loading..." className="mr-2" />
        <BrowserView>
          {property?.availability.baseGuests}
          {property?.availability.baseGuests === 1
            ? `${translate.t('guest').toUpperCase()}`
            : `${translate.t('guests').toUpperCase()}`}
        </BrowserView>
        <MobileView>x{property?.availability.baseGuests}</MobileView>
      </span>
      <span className="type-check ml-2">
        <img src={dorm} width="5%" alt="Loading..." className="mr-2" />
        <BrowserView>
          {property?.bedrooms}
          {property?.bedrooms === 1
            ? `${translate.t('bedroom').toUpperCase()}`
            : `${translate.t('bedrooms').toUpperCase()}`}
        </BrowserView>
        <MobileView>x{property?.bedrooms}</MobileView>
      </span>
      <span className="type-check ml-2">
        <img src={bath} width="5%" alt="Loading..." className="mr-2" />
        <BrowserView>
          {property?.bathrooms}
          {parseInt(property?.bathrooms) === 1
            ? `${translate.t('bath').toUpperCase()}`
            : `${translate.t('baths').toUpperCase()}`}
        </BrowserView>
        <MobileView>x{property?.bathrooms}</MobileView>
      </span>
    </div>
  );
}
export default PropertySummary;
