import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import footer from '../../assets/1. INICIO/23.svg';

import '../../components/componentStyles/CarouselHistoryStyle.css';
import '../../components/componentStyles/HistoryStyle.scss';

import { BrowserView, MobileView } from 'react-device-detect';
import translate from '../../components/languages';

import Header from './Header';
import Team from './Team';

function ScreenHistory() {
  return (
    <div>
      <NavBar altLogo={true} />
      <Header />
      <Team />
      <Footer />
    </div>
  );
}

export default ScreenHistory;
