/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import '../components/componentStyles/NavBarStyle.scss';
import Logo from '../assets/1. INICIO/logo.png';
import singleLogo from '../assets/icons/altLogo.svg';
import contactIcon from '../assets/icons/contact-header.svg';
import contactSupport from '../assets/contact-support.svg';
import translate from './languages';
import { useHistory } from 'react-router-dom';
import customToggleIcon from '../assets/1. INICIO/menu.svg';
import HeaderContact from '../Views/Home/HeaderContact';
import { isMobile } from 'react-device-detect';

function NavBar({ altLogo = false, props = false }) {
  let history = useHistory();
  const [showContact, setShowContact] = useState(false);

  function isActive(section) {
    return history.location.pathname.search(section) !== -1 ||
      (section === 'home' && history.location.pathname === '/')
      ? 'active'
      : '';
  }

  function openShortcutContact(e) {
    setShowContact(true);
  }

  const goToHome = () => {
    window.location.href = '/';
  };

  return (
    <>
      <nav
        id="navbar"
        className={`hover-fix-nav navbar navbar-dark navbar-expand-lg mt-4 nav body mb-1`}
        style={{
          position: 'absolute',
          zIndex: '79',
          width: '100%',
          marginTop: altLogo && !props ? '33px' : props ? '-54px' : '0px',
        }}
      >
        <div className="navbar-brand" onClick={() => goToHome()}></div>
        <div className="row nav-custom-mobile">
          <div className="col-xs-2">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{ border: 'none' }}
            >
              <img
                src={customToggleIcon}
                className="navbar-custom-toggler-icon"
                alt="custom toggle icon"
              />
            </button>
          </div>

          <div className="col-xs-8 hide-in-mobile">
            <a className="logo ml-4" href="/">
              {altLogo ? (
                <img
                  src={singleLogo}
                  width="120"
                  href="/"
                  alt="Loading..."
                  style={{ width: '62%' }}
                />
              ) : (
                <img src={Logo} width="120" href="/" alt="Loading..." />
              )}
            </a>
          </div>
        </div>

        <div
          className="collapse navbar-collapse body"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ml-auto align-items-navbar border-link">
            <li className="nav-item mr-1 ml-1 border-link ">
              <a
                className={`altNav nav-link ${isActive('contact')}`}
                onClick={(e) => openShortcutContact(e)}
                href="#"
              >
                <img
                  src={contactSupport}
                  width={isMobile ? 20 : 40}
                  className="mr-2 contactIcon"
                  href="/"
                  alt="Loading..."
                />
                {isMobile ? translate.t('navContact') : ''}
              </a>
            </li>
            <li className="nav-item mr-1 ml-1 border-link ">
              <a className={`altNav nav-link ${isActive('home')}`} href="/">
                {translate.t('navHome')}
              </a>
            </li>
            <li className="nav-item mr-1 ml-1 border-link">
              <a
                className={`altNav nav-link ${isActive('business-trip')}`}
                href="/business-trip"
              >
                {translate.t('navWork')}
              </a>
            </li>
            <li className="nav-item mr-1 ml-1 border-link">
              <a
                className={`altNav nav-link ${isActive('history')}`}
                href="/history"
              >
                {translate.t('navUs')}
              </a>
            </li>

            <li className="nav-item mr-3 ml-3 border-link">
              <a
                className={`altNav nav-link ${isActive('partners')}`}
                href="/partners"
              >
                {translate.t('navPartners')}
              </a>
            </li>
          </ul>
        </div>

        <HeaderContact show={showContact} />
      </nav>

      {altLogo && <div className="navbar-background"></div>}
    </>
  );
}

export default NavBar;
