import moment from 'moment';
import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import icon360 from '../../assets/11. FICHA/360.svg';
import icon1 from '../../assets/11. FICHA/cleaning.svg';
import icon2 from '../../assets/11. FICHA/cleaning2.svg';
import icon3 from '../../assets/11. FICHA/recipt.svg';
import Footer from '../../components/Footer';
import LocationMap from '../../components/Gmaps';
import NavBar from '../../components/NavBar';
import '../../components/componentStyles/CarouselCheckStyle.css';
import '../../components/componentStyles/CheckOutStyle.scss';
import '../../components/componentStyles/calendarHostfully.scss';
import translate from '../../components/languages';
import API from '../../utils/API';
import Helper, { Variables } from '../../utils/Helper';
import CheckoutCarousel from './CheckoutCarousel';

import washer from '../../assets/11. FICHA/washer.svg';
import tv from '../../assets/11. FICHA/tv.svg';
import wifi from '../../assets/11. FICHA/wifi.svg';
import ac from '../../assets/11. FICHA/ac.svg';

import 'react-alice-carousel/lib/alice-carousel.css';
import Carousel from './Carousel';
import { BrowserView, MobileView } from 'react-device-detect';

import { FullLoader } from '../../utils/carousel/Loader';
import useProperties from '../../utils/hooks/useProperties';
import { AMENITIES_LIST } from '../../utils/amenities/amenities';
import { ModalCheckoutAmenities } from './ModalAmenities';
import { getAmenities, HELPER_TSX } from '../../utils/HelperTSX';
import useProperty from '../../utils/hooks/useProperty';
import { PropertyReviews } from './PropertyReview';
import { FB } from '../../utils/Ads';
import PropertySummary from './PropertySummary';
import {
  DesktopContactAndWidgetCheckout,
  MobileContactAndWidgetCheckout,
} from './MobileContactAndWidgetCheckout';
import useScript from '../../utils/hooks/useScript';
import Availability from './Availability';

const scriptCapture = `/assets/js/leadCaptureWidget_2.0.js`;
const scriptOverrideCapture = `/assets/js/overrideLeadCaptureFinal.js`;
const scriptPicker = `/assets/js/pikaday.js`;
const heightIconCheckOut = 35;

function CheckoutScreen() {
  const location = useLocation();
  const propertyId = Helper.getParam('propertyId', location);
  const {
    description: propertyDescription,
    photos,
    roomPhotos,
    property: propertyNew,
    loading: loadingProperty,
    reco360,
  } = useProperty({
    uid: propertyId,
  });

  const [isFirstLoad, setFirstLoad] = useState(true);
  const [property, setProperty] = useState({});
  const [amenitiesList, setAmenities] = useState([]);

  const [checkoutGuest] = useState(Helper.getParam('guests', location));
  const [checkoutCheckIn] = useState(Helper.getParam('checkIn', location));
  const [checkoutCheckOut] = useState(Helper.getParam('checkOut', location));

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const statusPicker = useScript(scriptPicker);
  const statusWidgetCapture = useScript(scriptCapture);
  const scriptOverride = useScript(scriptOverrideCapture);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    let propertyId =
      location.state !== undefined && location.state.propertyId.propertyId
        ? location.state.propertyId
        : Helper.getParam('propertyId', location);

    if (isFirstLoad) {
      API.Web.getPropertyById({}, propertyId).then(async (response) => {
        let newObj = {
          ...response,
          address: {
            latitude: response.latitude,
            longitude: response.longitude,
          },
        };

        setProperty(newObj);
      });

      API.Web.getAmenities(propertyId).then((amenities) => {
        let amenitiesActived = Helper.filterTrueAmenities(
          Object.entries(amenities),
        );
        let amentiesParsed = parseAmenitiesName(amenitiesActived);
        setAmenities(amentiesParsed);
      });

      setFirstLoad(false);
      FB.viewContent();
    }
  }, [isFirstLoad, location]);

  useEffect(() => {
    if (
      statusWidgetCapture === 'ready' &&
      statusPicker === 'ready' &&
      property.uid !== undefined
    ) {
      const country = localStorage.getItem('country');
      new window.Widget('leadWidget', property.uid, {
        ...HELPER_TSX.getWidgetConfig(),
        lang: country === 'es' || country === null ? 'ES' : 'US',
        valuesByDefaults: {
          checkIn: { value: checkoutCheckIn },
          checkOut: { value: checkoutCheckOut },
          guests: { value: checkoutGuest },
          discountCode: { value: '' },
        },
      });

      setTimeout(() => {
        const form = document.querySelector('#leadWidget form');
        if (form) form.addEventListener('submit', handleEventSubmit);
      }, 6000);
    }

    return () => {
      const form = document.querySelector('#leadWidget form');
      if (form) {
        form.removeEventListener('submit', handleEventSubmit);
      }
    };
  }, [statusPicker, statusWidgetCapture, property.uid]);

  const handleEventSubmit = (e) => {
    e.preventDefault();
    FB.initiateCheckout();
  };

  function parseAmenitiesName(amenities) {
    return amenities.map((each) => each[0].replace('has', ''));
  }

  const { Abathroom, Agenerales, Ainfra, Akitchen } = getAmenities(propertyNew);
  const cantAmenities =
    Abathroom.length + Agenerales.length + Ainfra.length + Akitchen.length;
  const { carouselItems, purePhotosRoomArray, photosRoomList } =
    HELPER_TSX.preparePhotosCheckout(photos, roomPhotos);

  if (!property?.uid || !propertyNew?.uid) {
    return <FullLoader />;
  }

  return (
    <div>
      <NavBar altLogo={true} />

      <div style={{ paddingTop: '85px' }}>
        <div className="container-top-carousel mb-4">
          <CheckoutCarousel
            items={carouselItems}
            pureItems={purePhotosRoomArray}
          />
        </div>

        <ModalCheckoutAmenities
          closeModal={closeModal}
          modalIsOpen={modalIsOpen}
          propertiesList={[propertyNew]}
        />

        <div className="checkout-title-reservation-container">
          <div className="checkout-cont">
            <div className="checkout-title-prop checkout-mobile-container">
              {propertyDescription?.name}
            </div>
            <div className="city-check checkout-mobile-container">
              {property?.city}
            </div>

            {propertyNew && <PropertySummary {...propertyNew} />}

            <span className="checkout-dots checkout-mobile-container">
              ● ● ●
            </span>

            <div className="row">
              <div className="px-3 w-100">
                <br />

                <div className="text-description checkout-mobile-container">
                  <p>{propertyDescription?.summary}</p>
                </div>

                <MobileContactAndWidgetCheckout {...propertyNew} />

                <CheckoutAmenitiesBox
                  propertiesList={[propertyNew]}
                  loading={loadingProperty}
                />

                {/* Probably can be added to the component above */}
                <div
                  className="checkout-mobile-container mb-4"
                  style={{ cursor: 'pointer', width: 'fit-content' }}
                  onClick={() => openModal()}
                >
                  {amenitiesList?.length > 4 && (
                    <div
                      style={{
                        borderBottom: '1px solid black',
                        width: 'fit-content',
                      }}
                    >
                      <span className="checkout-show-all-amenities">
                        {translate.t('showAllAmenities')}
                      </span>{' '}
                      <span className="checkout-more-amenities">
                        +{cantAmenities - 4}
                      </span>
                    </div>
                  )}
                </div>

                {/* //custom carousel inside if desktop, other carousel if mobile */}
                {/* <Carousel
                  photosRoomList={photosRoomList}
                  purePhotosRoomArray={purePhotosRoomArray}
                /> */}

                <MobileSeparator />

                <div className="checkout-apart-includes-container">
                  <RentIncludesInformation />

                  {/* Online for mobile spaces */}
                  <MobileSeparator />

                  {/* Reco360 component */}
                  <Reco360 reco360={reco360} />

                  {/* Just text and items */}
                  <BookingProcessInformation />

                  {/* DesktopSeparatr */}
                  <DesktopSeparator />

                  {/* Hostfully calendar widget */}
                  <Availability
                    availabilityCalendarUrl={property?.availabilityCalendarUrl}
                    propertyName={property?.name}
                  />
                </div>
              </div>
            </div>

            <RegularSeparator />
            <PropertyReviews reviews={propertyNew?.reviewsExported} />
          </div>

          <DesktopContactAndWidgetCheckout {...propertyNew} />
        </div>

        <div className="container"></div>

        {property?.uid ? (
          <div className="location-map mt-5">
            <LocationMap
              zoom={15}
              properties={[property]}
              mapOptions={{ draggable: false, disableDefaultUI: true }}
            />
          </div>
        ) : null}
      </div>

      <Footer />
    </div>
  );
}

export default CheckoutScreen;

const CheckoutAmenitiesBox = ({ propertiesList, loading }) => {
  if (loading || propertiesList?.length === 0) return null;
  const property = propertiesList[0];
  const amenities = property?.amenities || [];

  const hasWaher = amenities.find(
    (each) =>
      each.amenity === AMENITIES_LIST.HAS_WASHER ||
      each.amenity === AMENITIES_LIST.HAS_WATER_KETTLE,
  );
  const hasTv = amenities.find(
    (each) => each.amenity === AMENITIES_LIST.HAS_SMART_TV,
  );
  const hasWifi = amenities.find(
    (each) => each.amenity === AMENITIES_LIST.HAS_INTERNET_WIFI,
  );
  const hasAirConditioning = amenities.find(
    (each) => each.amenity === AMENITIES_LIST.HAS_AIR_CONDITIONING,
  );

  return (
    <>
      <div className="title-description checkout-mobile-container include-title-green">
        <p>{translate.t('equipment')}</p>
      </div>
      <div className="checkout-amenities-icons my-4">
        {hasWaher && (
          <div className="checkout-amenities-cont">
            <img src={washer} alt="lavarropas" />
            <span>Lavarropas</span>
          </div>
        )}

        {hasTv && (
          <div className="checkout-amenities-cont">
            <img src={tv} alt="tv" />
            <span>Smart TV</span>
          </div>
        )}

        {hasWifi && (
          <div className="checkout-amenities-cont">
            <img src={wifi} alt="high speed wifi" />
            <span>High Speed wifi</span>
          </div>
        )}

        {hasAirConditioning && (
          <div className="checkout-amenities-cont">
            <img src={ac} alt="air conditioner" />
            <span>Aire acondicionado</span>
          </div>
        )}
      </div>
    </>
  );
};

const Reco360 = (props) => {
  return (
    <>
      <div className="title-description d-flex align-items-center checkout-mobile-container checkout-view-360">
        <span>View </span> <img src={icon360} width="60" />
      </div>

      <br />
      {props?.reco360 ? (
        <div className="">
          <div className="row">
            <div className={`col-md-12`}>
              <iframe
                width="100%"
                height="640"
                frameborder="0"
                allowFullScreen={true}
                allow="xr-spatial-tracking; gyroscope; accelerometer"
                src={props.reco360}
              ></iframe>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const BookingProcessInformation = () => {
  return (
    <>
      <div className="mt-4 title-description checkout-mobile-container include-title">
        <p>{translate.t('reserveProcess')}</p>
      </div>

      <div className="text-description checkout-mobile-container">
        <p>
          <span className="checkout-reserve-title">
            {translate.t('howWork')}
          </span>
          <br />
          <span className="checkout-reserve-content" style={{ color: 'black' }}>
            {translate.t('howWorkText')}
          </span>
        </p>
      </div>
      <div className="text-description checkout-mobile-container">
        <span className="checkout-reserve-title">
          {translate.t('paymentMethods')}
        </span>
        <br />
        <span className="checkout-reserve-content" style={{ color: 'black' }}>
          {translate.t('paymentMethodsText')}
        </span>
      </div>
      <br />
      <div className="text-description checkout-mobile-container">
        <p>
          <span className="checkout-reserve-title">
            {translate.t('longStays')}
          </span>
          <br />
          <span className="checkout-reserve-content" style={{ color: 'black' }}>
            {translate.t('longStaysText')}
          </span>
        </p>
      </div>
    </>
  );
};

function MobileSeparator() {
  return (
    <MobileView>
      <div
        style={{
          width: '25px',
          borderBottom: '1px solid rgba(32, 177, 157)',
          marginLeft: '30px',
        }}
      ></div>
      <br />
    </MobileView>
  );
}

function DesktopSeparator() {
  return (
    <BrowserView>
      <br />
      <div style={{ width: '100%', borderBottom: '1px solid black' }}></div>
      <br />
      <br />
    </BrowserView>
  );
}

function RegularSeparator() {
  return (
    <div
      className="mb-2 mt-4"
      style={{ width: '100%', borderBottom: '1px solid black' }}
    ></div>
  );
}

function RentIncludesInformation() {
  return (
    <>
      <div className="title-description checkout-mobile-container include-title">
        <span>{translate.t('rentInclude')}</span>
      </div>
      <br />
      <div className="text-description checkout-mobile-container">
        <p className="d-flex">
          <img
            src={icon1}
            height={heightIconCheckOut}
            className="mr-4"
            alt="Loading..."
            style={{ width: '40px' }}
          />
          <div className="d-flex flex-column align-items-start justify-content-start">
            <span className="checkout-includes-title">
              {translate.t('professionalClean')}
            </span>
            <span className="checkout-includes-content">
              {translate.t('biweeklyReview')}
            </span>
          </div>
        </p>
      </div>
      <div className="text-description checkout-mobile-container">
        <p className="d-flex">
          <img
            src={icon2}
            height={heightIconCheckOut}
            className="mr-4"
            alt="Loading..."
            style={{ width: '40px' }}
          />
          <div className="d-flex flex-column align-items-start justify-content-start">
            <span className="checkout-includes-title">
              {translate.t('amenitiesBath')}
            </span>
            <span className="checkout-includes-content">
              {translate.t('amenitiesBathText')}
            </span>
          </div>
        </p>
      </div>
      <div className="text-description checkout-mobile-container">
        <p className="d-flex">
          <img
            src={icon3}
            height={heightIconCheckOut}
            className="mr-4"
            alt="Loading..."
            style={{ width: '40px' }}
          />
          <div className="d-flex flex-column align-items-start justify-content-start">
            <span className="checkout-includes-title">
              {translate.t('services')}
            </span>
            <span className="checkout-includes-content">
              {translate.t('servicesText')}
            </span>
          </div>
        </p>
      </div>
    </>
  );
}
