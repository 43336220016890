import { BrowserView, MobileView } from 'react-device-detect';
import translate from '../../components/languages';
import { ModalContactProperty } from './ModalContact';
import { useState } from 'react';

//assets
import visa from '../../assets/11. FICHA/visa.png';
import master from '../../assets/11. FICHA/master.svg';
import american from '../../assets/11. FICHA/american.svg';
import paypal from '../../assets/11. FICHA/paypal.svg';
import { IProperty } from '../../utils/interfaces/hostfully.property.interface';

//Currently not used, but will be when stripe is implemented
// function generateLead(data = false) {
//     if (!data) {
//       var data = {
//         agencyUid: '064c3db9-f703-4609-8afc-24917b91f906',
//         propertyUid: location.state.propertyId,
//         checkInDate: checkoutCheckIn,
//         checkOutDate: checkoutCheckOut,
//         email: email,
//         status: 'NEW',
//         firstName: name,
//         lastName: lastName,
//         adultCount: checkoutGuest,
//         childrenCount: 0,
//         petCount: 0,
//         externalBookingID: 1,
//       };
//     }

//     API.Web.postLead(data);
//   }

function MobileContactAndWidgetCheckout(property: IProperty) {
  const [showModalContactProperty, setShowModal] = useState(false);

  function toggleModalContactProperty() {
    setShowModal(!showModalContactProperty);
  }

  return (
    <>
      <MobileView>
        <br />
        <div className="widget-checkout-container">
          <div className="checkout-book-now">
            <div id="leadWidget" className=""></div>
            <div className="card-check">
              <div className="text-reserva">
                <p>
                  {translate.t('onlineBooking').toString()}{' '}
                  {translate.t('onlineBookingText').toString()}{' '}
                  <strong style={{ color: '#009984' }}>
                    (+54) 11 2522-9682
                  </strong>
                </p>
                <button
                  className="button-reserva btn-outline"
                  onClick={() => {
                    toggleModalContactProperty();
                  }}
                >
                  {translate.t('contactUs').toString()}
                </button>
                {showModalContactProperty && (
                  <ModalContactProperty {...property} />
                )}
              </div>

              <CheckoutCards />
            </div>
          </div>
        </div>
      </MobileView>
      <MobileView>
        <div
          style={{
            width: '25px',
            borderBottom: '1px solid rgba(32, 177, 157)',
            marginLeft: '30px',
            marginBottom: '20px',
          }}
        ></div>
      </MobileView>
    </>
  );
}

function DesktopContactAndWidgetCheckout(property: IProperty) {
  const [showModalContactProperty, setShowModal] = useState(false);
  function toggleModalContactProperty() {
    setShowModal(!showModalContactProperty);
  }
  return (
    <>
      <div className="widget-checkout-container">
        <div className="sticky-container">
          <BrowserView>
            <div className="checkout-book-now">
              <div id="leadWidget" className=""></div>
              <div className="card-check">
                <div className="text-reserva">
                  <p style={{ fontSize: '12px', padding: '10px 0px' }}>
                    <strong>{translate.t('onlineBooking').toString()}</strong>{' '}
                    {translate.t('onlineBookingText').toString()}{' '}
                    <strong style={{ color: '#009984' }}>
                      (+54) 11 2522-9682
                    </strong>
                  </p>
                  <button
                    className="button-reserva btn-outline"
                    onClick={() => {
                      toggleModalContactProperty();
                    }}
                  >
                    {translate.t('contactUs').toString()}
                  </button>
                  {showModalContactProperty && (
                    <ModalContactProperty {...property} />
                  )}
                </div>

                <CheckoutCards />
              </div>
            </div>
          </BrowserView>
        </div>
      </div>
    </>
  );
}

export { DesktopContactAndWidgetCheckout, MobileContactAndWidgetCheckout };

function CheckoutCards() {
  return (
    <div className="checkout-cards mt-3">
      <img src={paypal} width="100%" height="100%" alt="Loading..." />
      <img src={visa} width="100%" height="100%" alt="Loading..." />
      <img src={master} width="100%" height="100%" alt="Loading..." />
      <img src={american} width="100%" height="100%" alt="Loading..." />
    </div>
  );
}
