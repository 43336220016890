import { Modal } from 'react-bootstrap';
import xbutton from '../../assets/11. FICHA/xbutton.svg';
import { useEffect } from 'react';
import { getAmenities } from '../../utils/HelperTSX';
import translate from '../../components/languages';
import { IProperty } from '../../utils/interfaces/hostfully.property.interface';
import { isMobile } from 'react-device-detect';

export type TPropsCheckoutModalAmenities = {
  closeModal: () => void;
  modalIsOpen: boolean;
  propertiesList: IProperty[];
};

const isSpanish =
  localStorage.getItem('country') === 'es' ||
  localStorage.getItem('country') === null ||
  localStorage.getItem('country') === undefined
    ? true
    : false;
const ModalCheckoutAmenities = ({
  closeModal,
  modalIsOpen,
  propertiesList,
}: TPropsCheckoutModalAmenities) => {
  useEffect(() => {}, [propertiesList]);

  if (propertiesList.length === 0) return null;
  const property = propertiesList[0];
  const {
    Agenerales: generales,
    Ainfra: infra,
    Akitchen: kitchen,
    Abathroom: bath,
  } = getAmenities(property);

  return (
    <Modal
      show={modalIsOpen}
      size="lg"
      onHide={() => closeModal()}
      className={`modalAmenitiesCheckout ${!isMobile ? 'p-4' : 'p-1'}`}
      centered
    >
      <div className="checkout-modal-container pb-4">
        <div className="checkout-modal-header pt-4">
          <h3 className="checkout-modal-title">
            {translate.t('checkout.modalAmenityTitle').toUpperCase()}
          </h3>
          <button className="checkout-modal-x-button" onClick={closeModal}>
            <img src={xbutton} className="x-button" alt="close button" />
          </button>
        </div>
        <br />
        <br />
        <div
          className="d-flex align-items-center"
          style={{ position: 'relative' }}
        >
          <h5
            className="checkout-modal-title"
            style={{ fontFamily: 'SharpGrotesk', fontWeight: 'bold' }}
          >
            {translate.t('checkout.modalAmenityGeneral').toUpperCase()}
          </h5>
          <div className="checkout-modal-line col"></div>
        </div>
        <div className="checkout-modal checkout-modal-amenities-container-5 mt-2">
          {generales.map((each) => {
            const langName = isSpanish ? each.amenity_es : each.amenity_en;
            return <span>{langName}</span>;
          })}
        </div>
        <div
          className="d-flex align-items-center mt-4"
          style={{ position: 'relative' }}
        >
          <h5
            className="checkout-modal-title"
            style={{ fontFamily: 'SharpGrotesk', fontWeight: 'bold' }}
          >
            {translate.t('checkout.modalAmenityKitchet').toUpperCase()}
          </h5>
          <div className="checkout-modal-line col"></div>
        </div>
        <div className="checkout-modal checkout-modal-amenities-container-4 mt-2">
          {kitchen.map((each) => {
            const langName = isSpanish ? each.amenity_es : each.amenity_en;
            return <span>{langName}</span>;
          })}
        </div>
        <div
          className="d-flex align-items-center mt-4"
          style={{ position: 'relative' }}
        >
          <h5
            className="checkout-modal-title"
            style={{ fontFamily: 'SharpGrotesk', fontWeight: 'bold' }}
          >
            {translate.t('checkout.modalAmenityBath').toUpperCase()}
          </h5>
          <div className="checkout-modal-line col"></div>
        </div>
        <div className="checkout-modal checkout-modal-amenities-container-2 mt-2">
          {bath.map((each) => {
            const langName = isSpanish ? each.amenity_es : each.amenity_en;
            return <span>{langName}</span>;
          })}
        </div>
        <div
          className="d-flex align-items-center mt-4"
          style={{ position: 'relative' }}
        >
          <h5
            className="checkout-modal-title"
            style={{
              fontFamily: 'SharpGrotesk',
              fontWeight: 'bold',
              width: 'fit-content',
            }}
          >
            {translate.t('checkout.modalAmenityInfra').toUpperCase()}
          </h5>
          <div className="checkout-modal-line col"></div>
        </div>
        <div className="checkout-modal checkout-modal-amenities-container-2 mt-2">
          {infra.map((each) => {
            const langName = isSpanish ? each.amenity_es : each.amenity_en;
            return <span>{langName}</span>;
          })}
        </div>
      </div>
    </Modal>
  );
};

export { ModalCheckoutAmenities };
