import './Loader.scss';
import welcomeLogo from '../../assets/welcome2ba.svg';

const Loader = () => {
  return <div className="loader"></div>;
};

const FullLoader = () => {
  return (
    <div className="fullLoader">
      <img src={welcomeLogo} alt="Loading" className="loader-image" />
    </div>
  );
};

export { FullLoader };
export default Loader;
