import { useRef, useState } from 'react';
import translate from '../../components/languages';
import { Modal, Col, Form, Row } from 'react-bootstrap';
import DateRangePicker, { DateRange } from 'rsuite/DateRangePicker';
import { PickerHandle } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import API from '../../utils/API';
import moment from 'moment';
import { Variables } from '../../utils/Helper';
import { FB } from '../../utils/Ads';
import { IProperty } from '../../utils/interfaces/hostfully.property.interface';

const { beforeToday } = DateRangePicker;
function ModalContactProperty(property: IProperty) {
  const [modalShow, setModalShow] = useState(true);
  const [name, setName] = useState('');
  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [selectedRange, setSelectedRange] = useState<
    [Date | undefined, Date | undefined]
  >([undefined, undefined]);
  const refRangePicker = useRef<PickerHandle | null>(null);

  function sendData() {
    const data = {
      name,
      lastName,
      phone,
      email,
      message,
      checkIn,
      checkOut,
      propertyName: property.name,
    };

    let str = `Nuevo contacto por una propiedad \n`;
    str += `nombre:${name} \n`;
    str += `apellido:${lastName} \n`;
    str += `tel:${phone} \n`;
    str += `email:${email} \n`;
    str += `mensaje:${message} \n`;
    str += `check in:${checkIn} \n`;
    str += `check out:${checkOut} \n`;
    str += `property:${property.name} ${window.location.href} \n`;

    FB.lead();
    API.Web.postSlackMessage({ text: str });
    setShowSuccessMessage(true);
  }

  const handleSelect = (newDate: Date) => {
    if (selectedRange[0] !== undefined && selectedRange[1] === undefined) {
      let dayDiff = moment(newDate).diff(moment(selectedRange[0]), 'days');
      if (dayDiff <= 0) {
        alert(translate.t('alertCheckoutWrongDate').toString());
        return;
      }

      setSelectedRange([selectedRange[0], newDate]);
      const dates = {
        checkInDate: moment(selectedRange[0]).format(
          Variables.hostfullyDateFormat,
        ),
        checkOutDate: moment(newDate).format(Variables.hostfullyDateFormat),
      };
      setCheckIn(
        moment(selectedRange[0]).format(Variables.hostfullyDateFormat),
      );
      setCheckOut(moment(newDate).format(Variables.hostfullyDateFormat));

      console.log('aca', dates);

      setTimeout(() => {
        const button = $('.rs-btn-primary');
        button.trigger('click');
      }, 500);
    } else {
      setSelectedRange([newDate, undefined]);
    }
  };

  console.log('checkin', checkIn);

  const pickerValue: DateRange | null = selectedRange.length
    ? [moment(selectedRange[0]).toDate(), moment(selectedRange[1]).toDate()]
    : null;

  const datePickerPlaceHolder = `${translate.t(
    'labelDateFrom',
  )} - ${translate.t('labelDateTo')} `;

  return (
    <>
      <div className="col font-2 cursor-footer">
        <span onClick={() => setModalShow(true)}>
          {translate.t('contact').toString()}
        </span>
      </div>
      <Modal
        show={modalShow}
        size="lg"
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="title-modal"
          >
            {translate.t('contactUs').toString()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5 className="text-modal">
              {translate.t('personalInformationAndBook').toString()}
            </h5>
            <Form>
              <Row>
                <DateRangePicker
                  ref={refRangePicker}
                  shouldDisableDate={beforeToday()}
                  onSelect={handleSelect}
                  defaultValue={pickerValue}
                  placeholder={datePickerPlaceHolder}
                  format="dd-MM-yyyy"
                  className="filter-my-date-range-picker-clean whiteDateRange mb-2"
                  menuClassName="test"
                  appearance="subtle"
                />
              </Row>
              <Row>
                <Col>
                  <input
                    className="forms-modal"
                    placeholder={translate.t('name')}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Col>
                <Col>
                  <input
                    className="forms-modal"
                    placeholder={translate.t('lastName')}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <input
                    className="forms-modal"
                    placeholder={translate.t('phone')}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <input
                    className="forms-modal"
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <textarea
                    className="forms-modal"
                    placeholder={translate.t('message')}
                    onChange={(e) => setMessage(e.target.value)}
                  >
                    {message}
                  </textarea>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>
        <br />
        <Modal.Footer>
          {showSuccessMessage ? (
            <button
              onClick={() => setModalShow(false)}
              className="button-modal"
            >
              {translate.t('modalContactThanks').toString()}
            </button>
          ) : (
            <button onClick={() => sendData()} className="button-modal">
              {translate.t('modalContactSend').toString()}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
export { ModalContactProperty };
